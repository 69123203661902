import React from 'react'
import DeviceConnectionRedirect, {
  ConnectionStatusMode,
} from '../DeviceConnectionRedirect'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import data from './resources/data.json'

const useStyles = makeStyles(() =>
  createStyles({
    redirect: {
      height: '100%',
    },
  }),
)

export type Props = {
  onClose: () => void
}

export default function ConnectThermostatConfirmation({ onClose }: Props) {
  const classes = useStyles()
  return (
    <DeviceConnectionRedirect
      mode={ConnectionStatusMode.Connected}
      statusText={data.statusText}
      buttonAction={onClose}
      className={classes.redirect}
    />
  )
}
