export default (provider: string) => ({
  title: 'Connect Smart Thermostat',
  subtitle:
    "Optiwatt uses your smart thermostat's login to connect your device.",
  tag: undefined,
  informationCards: [
    {
      title: 'Trusted & secure',
      subtitle:
        'Your information is handled securely with end-to-end encryption.',
      featherIcon: 'Shield',
    },
    {
      title: 'Permissions',
      subtitle: [
        `We use data from ${provider} to connect your thermostat to Optiwatt`,
        "We analyze your thermostat's data to provide usage insights",
      ],
    },
  ],
  buttonText: 'Connect',
})
