import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import ConnectThermostatInformationCard from '../ConnectThermostatInformationCard'
import ConnectThermostatSelector from '../ConnectThermostatSelector'
import ConnectThermostatPermissionsInformationCard from '../ConnectThermostatPermissionsInformationCard'
import ConnectThermostatConfirmation from '../ConnectThermostatConfirmation'
import { ThermostatBrand } from '@/types/brand'

const useStyles = makeStyles((theme) =>
  createStyles({
    padded: {
      padding: theme.spacing(8),
    },
  }),
)

const Padded = ({ children }: { children: JSX.Element }) => {
  const classes = useStyles()
  return <div className={classes.padded}>{children}</div>
}

export enum ThermostatConnectFlowStep {
  GetStarted,
  SelectThermostat,
  Permissions,
  Confirmation,
}

export interface Props {
  mode: ThermostatConnectFlowStep
  onGetStarted: () => void
  onBrandSelect: (brand: ThermostatBrand) => void
  brand?: ThermostatBrand
  thermostatConnected: boolean
  onClose: () => void
  onBackClick: () => void
  onSkip?: () => void
}

export default function ConnectThermostatFlow({
  mode,
  brand,
  onGetStarted,
  onSkip,
  onBrandSelect,
  onClose,
}: Props) {
  return (
    {
      [ThermostatConnectFlowStep.GetStarted]: (
        <ConnectThermostatInformationCard
          onGetStarted={onGetStarted}
          onSkip={onSkip}
        />
      ),
      [ThermostatConnectFlowStep.SelectThermostat]: (
        <ConnectThermostatSelector onBrandSelect={onBrandSelect} />
      ),
      [ThermostatConnectFlowStep.Permissions]: brand && (
        <Padded>
          <ConnectThermostatPermissionsInformationCard brand={brand} />
        </Padded>
      ),
      [ThermostatConnectFlowStep.Confirmation]: (
        <Padded>
          <ConnectThermostatConfirmation onClose={onClose} />
        </Padded>
      ),
    }[mode] ?? null
  )
}
