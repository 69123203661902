import { useEffect } from 'react'
import { useAppDispatch } from '../../../hooks'
import {
  CONNECT_THERMOSTAT_TYPE,
  postMessagetoMobileIfPresent,
} from '../../../utils/messageUtils'
import thermostatAuth from '../../../actions/thermostatAuth'
import { BroadcastChannel } from 'broadcast-channel'
import BroadcastChannelName from '../../../types/broadcastChannelName'
import GoogleSignInButton from '../../../app/features/branding/GoogleSignInButton'
import { thermostatsCollection } from '../../../reducers/thermostats'
import { isNativeMobile } from '@/authenticated/hooks/useIsMobile'
import { ThermostatBrandName } from '@/types/thermostatBrands'
import { Brand } from '@/types/brand'

function openOAuthUrl(oauthUrl: string) {
  if (isNativeMobile()) {
    postMessagetoMobileIfPresent(oauthUrl, CONNECT_THERMOSTAT_TYPE)
  } else {
    window.open(oauthUrl)
  }
}

const onThermostatConnectionStart = {
  ecobee() {
    const params = {
      client_id: process.env.REACT_APP_ECOBEE_CLIENT_ID as string,
      redirect_uri: process.env.REACT_APP_ECOBEE_REDIRECT_URI as string,
      state: process.env.REACT_APP_ECOBEE_REDIRECT_URI as string,
      scope: process.env.REACT_APP_ECOBEE_SCOPE as string,
      response_type: 'code',
    }
    const ecobeeOAuthUrl = `https://api.ecobee.com/authorize?${new URLSearchParams(
      params,
    )}`
    openOAuthUrl(ecobeeOAuthUrl)
  },
  honeywell() {
    const params = {
      client_id: process.env.REACT_APP_HONEYWELL_CLIENT_ID as string,
      redirect_uri: process.env.REACT_APP_HONEYWELL_REDIRECT_URI as string,
      state: process.env.REACT_APP_HONEYWELL_REDIRECT_URI as string,
      response_type: 'code',
    }
    const honeywellOAuthUrl = `https://api.honeywell.com/oauth2/authorize?${new URLSearchParams(
      params,
    )}`
    openOAuthUrl(honeywellOAuthUrl)
  },
  nest() {
    const PROJECT_ID = process.env.REACT_APP_NEST_PROJECT_ID
    const params = {
      client_id: process.env.REACT_APP_NEST_CLIENT_ID as string,
      redirect_uri: process.env.REACT_APP_NEST_REDIRECT_URI as string,
      state: process.env.REACT_APP_NEST_REDIRECT_URI as string,
      access_type: 'offline',
      prompt: 'consent',
      response_type: 'code',
      scope: process.env.REACT_APP_NEST_SCOPE as string,
    }
    const nestOAuthUrl = `https://nestservices.google.com/partnerconnections/${PROJECT_ID}/auth?${new URLSearchParams(
      params,
    )}`
    openOAuthUrl(nestOAuthUrl)
  },
}

type Props = {
  brand: Brand
}

function useViewModel({ brand }: Props) {
  const dispatch = useAppDispatch()

  useEffect(() => {
    const bc = new BroadcastChannel(BroadcastChannelName.ThermostatRedirect)
    bc.onmessage = (
      eventMessage: Partial<{ success: boolean; brand: ThermostatBrandName }>,
    ) => {
      const { success, brand } = eventMessage
      if (!success || !brand) {
        return
      }
      dispatch(thermostatAuth(brand).actions.setAuth(success))
      // invalidate the thermostats collection so that the thermostat list is refreshed
      dispatch(thermostatsCollection.actions.invalidate())
    }
  }, [])

  const onConnect =
    onThermostatConnectionStart[
      brand.name as keyof typeof onThermostatConnectionStart
    ]

  const providerName = {
    ecobee: 'Ecobee',
    honeywell: 'Resideo',
    nest: 'Google Home',
  }[brand.name] as string

  return {
    onConnect,
    buttonComponent:
      brand.name === 'nest' ? (
        <GoogleSignInButton
          onClick={onConnect}
          id="connect-thermostat-google-signin-button"
        />
      ) : undefined,
    providerName,
  }
}

export default useViewModel
