import { brands as Brands, ThermostatBrandName } from '@/types/thermostatBrands'
import { remoteConfig } from '../../../firebase'
import { useIsExperimentalUser } from '../../hooks/useIsExperimentalUser'

function useViewModel() {
  const isExperimentalUser = useIsExperimentalUser()
  let comingSoonBrandNames =
    (remoteConfig?.getArray(
      'coming_soon_thermostat_brands',
    ) as ThermostatBrandName[]) ?? []
  const brandsInWarningNames =
    (remoteConfig?.getArray(
      'warning_thermostat_brands',
    ) as ThermostatBrandName[]) ?? []
  if (isExperimentalUser) {
    comingSoonBrandNames = []
  }
  /**
   * Precedence:
   * 1. Coming Soon Brands
   * 2. Brands in Warning
   * 3. All other brands
   */
  const brands = Brands.filter(
    (brand) =>
      !comingSoonBrandNames.includes(brand.name as ThermostatBrandName) &&
      !brandsInWarningNames.includes(brand.name as ThermostatBrandName),
  )
  const brandsInWarning = Brands.filter(
    (brand) =>
      brandsInWarningNames.includes(brand.name as ThermostatBrandName) &&
      !comingSoonBrandNames.includes(brand.name as ThermostatBrandName),
  )
  const comingSoonBrands = Brands.filter((brand) =>
    comingSoonBrandNames.includes(brand.name as ThermostatBrandName),
  )
  return {
    brands,
    comingSoonBrands,
    brandsInWarning,
  }
}

export default useViewModel
