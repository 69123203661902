import React from 'react'
import theme from '../../../v2/theme'
import { ThemeProvider } from '@material-ui/core/styles'
import { ViewModelComponentProps } from '../../../utils/typeUtilities'
import ConnectThermostatSelector, {
  Props as ComponentProps,
} from './ConnectThermostatSelector'
import useViewModel from './useViewModel'

export type Props = ViewModelComponentProps<ComponentProps, typeof useViewModel>

export default function ThemedConnectThermostatSelector(props: Props) {
  const viewModel = useViewModel()
  return (
    <ThemeProvider theme={theme}>
      <ConnectThermostatSelector {...viewModel} {...props} />
    </ThemeProvider>
  )
}
