import React from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from '../../../v2/theme'
import ConnectThermostat, { Props } from './ConnectThermostat'

export default (props: Props) => {
  return (
    <ThemeProvider theme={theme}>
      <ConnectThermostat {...props} />
    </ThemeProvider>
  )
}
