import ConnectDeviceInformationCard from '../ConnectDeviceInformationCard'
import data from './resources/data.json'
import Lottie from 'lottie-react'
import connectThermostatAnimation from './resources/images/connect-thermostat-lottie.json'

const ConnectThermostatAnimation = (
  <Lottie animationData={connectThermostatAnimation} loop />
)

export interface Props {
  onGetStarted: () => void
  onSkip?: () => void
}

const ConnectThermostatInformationCard = ({ onGetStarted, onSkip }: Props) => {
  return (
    <ConnectDeviceInformationCard
      id="connect-thermostat-information-card"
      onboarding
      headerlessOnDesktop={false}
      media={ConnectThermostatAnimation}
      title={data.title}
      subtitle={data.subtitle}
      tag={data.tag ?? undefined}
      informationCards={data.informationCards}
      onClick={onGetStarted}
      onSkipClick={onSkip}
      padContent
    />
  )
}

export default ConnectThermostatInformationCard
