import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import ConnectDeviceInformationCard from '../ConnectDeviceInformationCard'
import dataFunc from './resources/data'

const useStyles = makeStyles((theme) =>
  createStyles({
    informationCardHeaderText: {
      paddingRight: theme.spacing(14),
    },
  }),
)

export interface Props {
  onConnect: () => void
  onBackClick?: () => void
  buttonComponent?: React.ReactNode
  providerName: string
}

const ConnectThermostatPermissionsInformationCard = ({
  onConnect,
  onBackClick,
  buttonComponent,
  providerName,
}: Props) => {
  const classes = useStyles()
  const data = dataFunc(providerName)
  return (
    <ConnectDeviceInformationCard
      id="connect-thermostat-permissions-information-card"
      onboarding
      title={data.title}
      subtitle={data.subtitle}
      button={buttonComponent}
      buttonText={data.buttonText}
      tag={data.tag}
      informationCards={data.informationCards}
      onClick={onConnect}
      onBackClick={onBackClick}
      sticky={false}
      classes={{ headerText: classes.informationCardHeaderText }}
    />
  )
}

export default ConnectThermostatPermissionsInformationCard
