import React from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from '../../../v2/theme'
import ConnectThermostatInformationCard, {
  Props,
} from './ConnectThermostatInformationCard'

export default (props: Props) => {
  return (
    <ThemeProvider theme={theme}>
      <ConnectThermostatInformationCard {...props} />
    </ThemeProvider>
  )
}
