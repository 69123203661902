import './styles.scss'
import googleSrc from './resources/images/google_dark_normal.webp'
import { Text } from '../../../../components'
import formatClasses from '../../../../utils/classes/formatClasses'
import { Identifiable } from '@/types'

export interface Props extends Identifiable {
  onClick: () => void
}

export default function GoogleSignInButton(props: Props) {
  const classes = {
    root: formatClasses(['googlesignin-button']),
    logo: formatClasses(['googlesignin-logo']),
    text: formatClasses(['googlesignin-text']),
  }
  return (
    <button className={classes.root} id={props.id} onClick={props.onClick}>
      <img className={classes.logo} src={googleSrc} alt="Google logo" />
      <Text className={classes.text}>Sign in with Google</Text>
    </button>
  )
}
