import React from 'react'
import ConnectThermostatFlow, {
  Props as ComponentProps,
} from './ConnectThermostatFlow'
import { ViewModelComponentProps } from '../../../utils/typeUtilities'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from '../../../v2/theme'
import useViewModel from './useViewModel'

export type Props = ViewModelComponentProps<ComponentProps, typeof useViewModel>

export default function ThemedConnectThermostatFlow(props: Props) {
  const viewModel = useViewModel(props)
  return (
    <ThemeProvider theme={theme}>
      <ConnectThermostatFlow {...viewModel} {...props} />
    </ThemeProvider>
  )
}
