import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core'
import { Paper, PaperProps, Grid as MuiGrid, styled } from '@material-ui/core'
import { spacing } from '@material-ui/system'
import BrandSelector from '../BrandSelector'
import { Brand, ThermostatBrand } from '@/types/brand'

const Grid = styled(MuiGrid)(spacing)

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      padding: theme.spacing(6),
    },
    container: {
      margin: 'auto',
    },
  }),
)

export interface Props extends PaperProps {
  brands: Brand[]
  comingSoonBrands: Brand[]
  brandsInWarning: Brand[]
  onBrandSelect: (brand: ThermostatBrand) => void
  onBackClick?: () => void
}

export default function ConnectThermostatSelector({
  brands,
  comingSoonBrands,
  brandsInWarning,
  onBrandSelect,
  onBackClick,
  ...rest
}: Props) {
  const classes = useStyles()

  return (
    <Grid container>
      <Grid className={classes.container} item xs={12}>
        <Paper className={classes.wrapper} elevation={0} {...rest}>
          <Grid container item alignItems="center" justifyContent="center">
            <Grid item xs={12}>
              <BrandSelector
                id="connect-thermostat-selector"
                brandList={brands}
                comingSoonBrandList={comingSoonBrands}
                brandInWarningList={brandsInWarning}
                onButtonSelect={(brand: Brand | ThermostatBrand) =>
                  onBrandSelect(brand as ThermostatBrand)
                }
                includeInsetBottom={false}
                title="Select Your Brand"
                handleGoBack={onBackClick}
                brandType={'thermostat'}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
