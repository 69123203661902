import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import ConnectThermostatFlow, {
  Props as ConnectThermostatFlowProps,
} from '../../components/ConnectThermostatFlow'
import FullScreenSingleCard from '../../components/FullScreenSingleCard'
import { NavigationPage } from '@/app/components'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      minHeight: '380px',
      [theme.breakpoints.down('sm')]: {
        height: '100%',
      },
    },
  }),
)

export type Props = ConnectThermostatFlowProps

export default function ConnectThermostat(props: Props) {
  const classes = useStyles()
  return (
    <NavigationPage id="connect-thermostat-page">
      <NavigationPage.SingleCardContent>
        {/* <FullScreenSingleCard className={classes.container}> */}
        <ConnectThermostatFlow {...props} />
        {/* </FullScreenSingleCard> */}
      </NavigationPage.SingleCardContent>
    </NavigationPage>
  )
}
