import { useState } from 'react'
import { useDispatch } from 'react-redux'
import thermostatAuth from '../../../actions/thermostatAuth'
import { useAppSelector } from '../../../hooks'
import { ThermostatConnectFlowStep } from './ConnectThermostatFlow'
import { useNavigation } from '@/app/hooks'
import { useDeviceSelectionRedirectUrl } from '@/hooks/useDeviceSelectionRedirectUrl'
import { surveyResponsesCollection } from '@/reducers/surveyResponses'
import { Brand, ThermostatBrand } from '@/types/brand'
import { ThermostatBrandName } from '@/types/thermostatBrands'
import { thermostatsCollection } from '@/reducers/thermostats'

function getThermostatConnectFlowStep(
  gotStarted: boolean,
  selectedBrand: Brand | undefined,
  thermostatConnected: boolean,
) {
  if (!gotStarted) {
    return ThermostatConnectFlowStep.GetStarted
  } else if (!selectedBrand) {
    return ThermostatConnectFlowStep.SelectThermostat
  } else if (!thermostatConnected) {
    return ThermostatConnectFlowStep.Permissions
  }
  return ThermostatConnectFlowStep.Confirmation
}

interface Props {
  onSkip?: () => void
}

function useViewModel(props: Props) {
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const [gotStarted, setGotStarted] = useState(false)
  const [selectedBrand, setSelectedBrand] = useState<ThermostatBrand>()
  const thermostatConnected = useAppSelector((state) => {
    return [state.nestAuth, state.ecobeeAuth, state.honeywellAuth]
      .map((auth) => auth.success)
      .some(Boolean)
  })
  const url = useDeviceSelectionRedirectUrl(true)
  return {
    mode: getThermostatConnectFlowStep(
      gotStarted,
      selectedBrand,
      thermostatConnected,
    ),
    brand: selectedBrand,
    thermostatConnected,
    onGetStarted() {
      setGotStarted(true)
    },
    onSkip: props.onSkip,
    onBrandSelect(brand: ThermostatBrand) {
      setSelectedBrand(brand)
    },
    onClose() {
      dispatch(thermostatsCollection.actions.invalidate())
      dispatch(surveyResponsesCollection.actions.fetch())
      navigation.push(url)
      setGotStarted(false)
      setSelectedBrand(undefined)
      if (selectedBrand?.name) {
        dispatch(
          thermostatAuth(
            selectedBrand.name as ThermostatBrandName,
          ).actions.setAuth(false),
        )
      }
    },
    onBackClick() {
      const currentMode = getThermostatConnectFlowStep(
        gotStarted,
        selectedBrand,
        thermostatConnected,
      )
      switch (currentMode) {
        case ThermostatConnectFlowStep.GetStarted:
          navigation.goBack()
          break
        case ThermostatConnectFlowStep.SelectThermostat:
          setGotStarted(false)
          break
        case ThermostatConnectFlowStep.Permissions:
          setSelectedBrand(undefined)
          break
        default: // add other cases if need for other steps
      }
    },
  }
}

export default useViewModel
